
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio-primary: mat.define-palette(mat.$indigo-palette);
$portfolio-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portfolio-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio-theme: mat.define-light-theme((
  color: (
    primary: $portfolio-primary,
    accent: $portfolio-accent,
    warn: $portfolio-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portfolio-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 30px ;
}
body {
   margin: 0;
   font-family: 'Quicksand', sans-serif;
   background-color: var(--background-color);
   }

   .headerdiv{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
   }

.title{
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px;
  font-family: 'HindSiliguri', sans-serif;
  font-weight: bold;
}

.subtitle{
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  padding: 10px 0px;
}

@font-face {
  font-family: 'HindSiliguri';
  src: url('./assets/fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'HindSiliguri';
  src: url('./assets/fonts/HindSiliguri/HindSiliguri-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


a{
  color: var(--secondary-blue)

}
a:hover{
  color: var(--primary-blue)

}
